<template>
  <div>
    <div class="ps-block--vendor-dashboard">
      <div
          class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
      >
        <h3>Runner Categories</h3>
        <div class="justify-content-between">
          &nbsp;&nbsp;
          <router-link
              :to="{ name: 'admin_create_runner_category' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-plus"></i> Create Category</router-link
          >
          &nbsp;&nbsp;
          <router-link
              :to="{ name: 'admin_runners' }"
              v-if="auth.isAuthenticated"
              class="ps-btn btn-sm"
          ><i class="icon-list"></i> Runners</router-link
          >
        </div>
      </div>
      <div class="ps-block__content">

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor table-bordered">
            <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="runnerCategory in runnerCategories" :key="runnerCategory.id">
              <td>{{ runnerCategory.name }}</td>
              <td>
                <router-link :to="{name: 'admin_edit_runner_category', params: {id: runnerCategory.id}}"><i class="icon-pencil"></i></router-link>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr v-if="(runnerCategories.length === 0) && !isLoading">
              <td colspan="6" class="text-center m-4 text-danger p-4">No runner categories available</td>
            </tr>
            <tr v-else-if="isLoading">
              <td colspan="6" class="text-center m-4 text-danger">Loading runner categories...</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "ListRunnerCategory",
  data() {
    return {
      runnerCategories:[],
      total: 0,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getRunners();
  },
  methods: {
    getRunners() {
      this.isLoading = true
      axios.get('runners/categories').then((response) => {
        this.isLoading = false
        this.runnerCategories = response.data
      }).catch((error) => {
        this.isLoading = false
      });
    },
  },
}
</script>

<style scoped>

</style>